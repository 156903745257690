.shadowCustom1 {
    text-shadow: 3px 0px 7px rgba(134,123,111,0.8), 
	  -3px 0px 7px rgba(134,123,111,0.8), 
	  0px 4px 7px rgba(134,123,111,0.8);
}
.shadowCustom2 {
    text-shadow: 3px 0px 7px rgba(135,120,95,0.8), 
	  -3px 0px 7px rgba(135,120,95,0.8), 
	  0px 4px 7px rgba(135,120,95,0.8);
}
.shadowCustom3 {
    text-shadow: 3px 0px 7px rgba(176,164,161, 0.8), 
	  -3px 0px 7px rgba(176,164,161,0.8), 
	  0px 4px 7px rgba(176,164,161,0.8);
}
// rgb(97,57,20)