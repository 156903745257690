.modalDetail > * {
    height: 1.5rem;
}
.wrapper {
    text-align: center;
    display:flex;
    justify-content: center;
    position: absolute;
    top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.wrapperImage {
    text-align: center;
    display: flex;
    justify-content: center;
    position: absolute;
    left: -65%;

    @media screen and (max-width: 992px) {
        text-align: center;
        display: flex;
        justify-content: center;
        position:inherit;
        // position: absolute;
        // left: -65%;
    }
}